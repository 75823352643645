export default function CallToAction({ subtitle, title, description }) {
  if (subtitle === "") {
    subtitle = "What is CodeNet?";
  }
  if (title === "") {
    title = (
      <p>
        Tech team building for professionals <br />
        with sports and activites{" "}
      </p>
    );
  }
  if (description === "") {
    description = (
      <p>
        CodeNet organises team building / team boosting experiences. <br />
        You can choose a course about a specific topic (AI & Tech topics) and a
        preferred location.
        <br /> After lessons we will organise sport sessions and activites: for
        example <br />
        what about Machine Learning course in Tenerife (Canary Islands - Spain)
        with surf lessons in the sunset ?
      </p>
    );
  }

  return (
    <div className="mx-4 md:mx-0 py-12 md:bg-hero-pattern md:bg-cover md:text-white bg-fixed">
      <div
        className="max-w-5xl rounded-3xl overflow-hidden mx-auto 
      text-center h-full md:p-12 sm:pb-10 xs:p-3 md:bg-black 
      md:bg-opacity-80"
      >
        <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
          {subtitle}
        </h2>
        <h2 className="text-3xl font-extrabold sm:text-4xl">{title}</h2>
        <div className="text-xl mt-4">{description}</div>
      </div>
    </div>
  );
}
