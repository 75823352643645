import classroom from "../images/classroom.png";
import map from "../images/map.png";
import surf from "../images/surf.png";

export default function BasicSection({ subtitle, title, description }) {
  if (subtitle === "") {
    subtitle = "Choose the best combintation for you";
  }
  if (title === "") {
    title = "Personalise your team building";
  }
  if (description === "") {
    description = "";
  }

  return (
    <div className="mb-10">
      <div className="py-12 md:text-black bg-fixed text-center">
        <div className="max-w-3xl m-auto">
          <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            {subtitle}
          </h2>
          <h2 className="text-3xl font-extrabold sm:text-4xl">{title}</h2>
          <div className="text-xl">
            <div className="px-5 py-5">{description}</div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl m-auto flex flex-row flex-wrap justify-evenly text-center">
        <div className="w-1/3 min-w-[20rem] my-2">
          <div className="flex justify-center">
            <div className="flex flex-col">
              <a href="#courses_section">
                <p className="hover:bg-black hover:text-white text-xl border-solid border-2 border-black p-[0.5rem] rounded-[4rem] my-3 w-[3rem]">
                  1
                </p>
              </a>
            </div>
          </div>

          <div className="flex justify-center">
            <img src={classroom} className="w-1/2 md:w-1/4" />
          </div>
          <p className="tracking-wide uppercase w-2/3 m-auto my-2">
            Choose one or more courses
          </p>
        </div>
        <div className="w-1/3 min-w-[20rem] my-2">
          <div className="flex justify-center">
            <div className="flex flex-col">
              <a href="#locations_section">
                <p className="hover:bg-black hover:text-white text-xl border-solid border-2 border-black p-[0.5rem] rounded-[4rem] my-3 w-[3rem]">
                  2
                </p>
              </a>
            </div>
          </div>
          <div className="flex justify-center">
            <img src={map} className="w-1/2 md:w-1/4" />
          </div>
          <p className="tracking-wide uppercase m-auto my-2">
            <span>Choose one or more locations. </span>
            <br />
            <span>Each locations has some available sports/activities</span>
          </p>
        </div>
        <div className="w-1/3 min-w-[20rem] my-2">
          <div className="flex justify-center">
            <div className="flex flex-col">
              <a href="#request_section">
                <p className="hover:bg-black hover:text-white text-xl border-solid border-2 border-black p-[0.5rem] rounded-[4rem] my-3 w-[3rem]">
                  3
                </p>
              </a>
            </div>
          </div>
          <div className="flex justify-center">
            <img src={surf} className="w-1/2 md:w-1/4" />
          </div>
          <p className="tracking-wide uppercase w-2/3 m-auto my-2">
            Send your inquiry
          </p>
        </div>
      </div>
      <div className="py-12 md:text-black bg-fixed text-center">
        <div className="m-auto">
          <h2 className="text-xl md:text-2xl">
            <p>
              (for example what about...) <br />
              <a className="underline decoration-sky-500">
                Deep Learning lessons
              </a>{" "}
              in{" "}
              <a className="underline decoration-lime-500">Tenerife Island</a>{" "}
              and{" "}
              <a className="underline decoration-indigo-500">surf lessons</a>
            </p>
          </h2>
        </div>
      </div>
    </div>
  );
}
