import emailjs from "@emailjs/browser";

const sendEmail = ({
  email,
  selection,
  chosenPeriod: period,
  setStatusCode,
}) => {
  const { locations, courses } = selection;
  const templateParams = {
    email,
    period,
    locations,
    courses,
  };

  emailjs
    .send(
      "service_sxvhnz9",
      "template_8e3j2hs",
      templateParams,
      "GHq9Ilq7srFfPe-D-"
    )
    .then(
      function (response) {
        setStatusCode(response);
        console.log("SUCCESS!", response.status, response.text);
      },
      function (error) {
        setStatusCode(error);
        console.log("FAILED...", error);
      }
    );
};

export default sendEmail;
