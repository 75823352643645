import { NumberedMenuWhite } from "../components/NumberedMenu";

export const months = [
  "May 2023",
  "June 2023",
  "July 2023",
  "August 2023",
  "September 2023",
  "October 2023",
  "November 2023",
  "December 2023",
  "January 2024",
  "February 2024",
  "March 2024",
  "April 2024",
  "May 2024",
  "June 2024",
  "July 2024",
  "August 2024",
  "September 2024",
  "October 2024",
  "November 2024",
  "December 2024",

];

export default function ComposeExperience({
  subtitle,
  title,
  chosen_locations,
  chosen_courses,
  handleChange,
  handleSubmit,
  email,
  handleChosenPeriod,
  statusCode,
}) {
  if (subtitle === "") {
    subtitle = "";
  }
  if (title === "") {
    title = "";
  }

  return (
    <div
      id="request_section"
      className="px-4 py-[7rem] bg-gradient-to-r from-blue-800 to-pink-600 md:text-white bg-fixed"
    >
      <div className="flex flex-col lg:flex-row px-4">
        <div className="pr-10">
          <NumberedMenuWhite active={["1", "2", "3"]} />
        </div>
        <div className="basis-3/12 md:ml-[1.5rem]">
          <p className="leading-6 text-white font-semibold tracking-wide uppercase">
            Choose a period
          </p>
          <p className="text-4xl py-2 text-white mb-0 font-extrabold sm:text-4xl">
            Send your request
          </p>
          <p className="text-white mb-5 lg:mb-0">
            Send your inquiry and we will recontact you asap with more details
            about your request.
          </p>
        </div>
        <div
          className=" basis-1/2 rounded-3xl overflow-hidden mx-auto 
      text-center h-full p-10 sm:pb-10 xs:p-3 bg-slate-900
      bg-opacity-80"
        >
          <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            {subtitle}
          </h2>
          <h2 className="text-3xl font-extrabold sm:text-4xl">{title}</h2>
          <div className="text-xl">
            <div className="flex lg:flex-row flex-col justify-between items-baseline md:items-start gap-x-1">
              <div className="basis-1/3 items-baseline text-left grow lg:w-1/2 min-h-[7rem] text-black rounded-md overflow-hidden">
                <p className="text-2xl text-white mb-0 font-extrabold">
                  Chosen Courses
                </p>
                {chosen_courses.map((el, ind) => (
                  <span
                    key={ind}
                    className="inline-block text-xl m-2 ml-0 bg-indigo-600 text-white font-medium mr-2 px-2.5 py-0.5 rounded-full"
                  >
                    {el}
                  </span>
                ))}
              </div>
              <div className="basis-1/3 text-left grow lg:w-1/2 min-h-[7rem] text-black rounded-md overflow-hidden">
                <p className="text-2xl text-white mb-0 font-extrabold">
                  Chosen Locations
                </p>
                {chosen_locations.map((el, ind) => (
                  <span
                    key={ind}
                    className="inline-block text-xl m-2 ml-0 bg-indigo-600 text-white font-medium mr-2 px-2.5 py-0.5 rounded-full"
                  >
                    {el}
                  </span>
                ))}
              </div>
            </div>
            <div className="mb-5 flex flex-col text-left text-black overflow-hidden shrink">
              <p className="text-2xl py-2 text-white mb-0 font-extrabold">
                Choose a preferred period
              </p>
              <select
                className="items-center rounded-lg"
                name="chosen_period"
                id="chosen_period"
                onChange={handleChosenPeriod}
              >
                {months.map((month, ind) => (
                  <option key={ind} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <p className="text-left text-2xl py-2 text-white font-extrabold">
                Type your email, we will recontact you
              </p>
              <input
                className="text-black w-full rounded-lg"
                type="text"
                value={email}
                onChange={handleChange}
              />
              <div>
                <div className="mt-7 ">
                  <input
                    type="submit"
                    value="Request info"
                    className="cursor-pointer mt-4 items-center w-full justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                  />
                </div>
              </div>
              {statusCode.status === "Wrong" && (
                <div className="mt-3 text-white text-xl bg-red-500 rounded-lg p-3">
                  Error
                  <ul>
                    <li>-Be sure you chose at least one location</li>
                    <li>-Be sure you chose at least one course</li>
                    <li>-The email must be valid</li>
                  </ul>
                </div>
              )}
              {statusCode.status === 200 && (
                <p className="mt-3 text-white text-2xl bg-emerald-500 rounded-lg p-3">
                  We correctly received your request. <br />
                  We will contact you asap. Have a nice day!
                </p>
              )}
              {/* {statusCode.status !== 200 &&
              statusCode.status !== "" &&
              "Something is wrong, email NOT sent. Please write to carlo.ceriotti[at]hotmail.it"} */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
