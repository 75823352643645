import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";

const description = (
  <div>
    <p class="my-2">
      This is a <b>TECHNICAL</b> course (Python coding 🐍)
    </p>
    <p class="my-2">
      Curious about how deep learning works? <br />
      You would like to understand how Neural Networks learn complex tasks? With
      this theorerical and <b>hands-on course</b>, you will learn this new
      world. <br />
      <b>Bring your laptop and join us!</b>
    </p>
  </div>
);

const subtitle = <p>Learn the principles of Deep Learning</p>;

const description_course = (
  <div>
    <p className="text-justify mb-5">
      <span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
        This is a <b>TECHNICAL</b> course (Python coding 🐍)
      </span>
    </p>
    <p>Looking to get a grip on Deep Learning and how to create models?</p>
    <p>
      We will explore Deep Learning and why it is different from Machine
      Learning.
    </p>
    <p>
      At the end of the course, you will have gained a solid grasp on how a NN
      is working, is trained, how to make inferences and how to correctly tune a
      NN choosing its structure and complexity for your data.
    </p>
    <p>
      The course is <b>hands-on</b>: we will use Python as coding language with{" "}
      <b>PyTorch</b> as deep learning library.
    </p>
  </div>
);

const topics = [
  {
    title: "What is Deep Learning",
  },
  {
    title: "What is a NN and how it works in details",
  },
  {
    title: "How it is trained, what is a loss function and what is an epoch",
  },
  {
    title: "How to choose the correct model complexity fitting your data",
  },
  {
    title: "How to avoid 'traps' and advices from experts",
  },
];

export default function DeepLearning() {
  return (
    <>
      {/* <Banner /> */}
      <HeaderCourse
        title="Deep Learning"
        subtitle={subtitle}
        bg_image="bg-deeplearning"
      />
      <CallToAction2
        subtitle="CodeNet"
        title="AI for Executives, managers and CEO"
        description={description}
      />
      <TextBlock
        short_uptitle={"Discover Deep Learning with CodeNet"}
        title={"Deep Learning - theory and practice"}
        description={description_course}
        topics={topics}
      />
      <Mentor mentor_names={["Carlo Ceriotti", "Cristiano De Nobili"]} />
      {/* <Venue /> */}
      {/* <ImageGallery /> */}
      {/* <Prices /> */}
      <Footer />
    </>
  );
}
