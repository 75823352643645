import Topic from "../components/Topics";
import left_image from "../images/ML_img4.jpg";
import syllabus from "../syllabus/NLP-program-CodeNet.pdf";

export default function TextBlock({
  short_uptitle,
  title,
  description,
  topics,
}) {
  return (
    <div id="section1">
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src={left_image}
              alt=""
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                {short_uptitle}
              </h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h3>
              <div className="my-8 text-lg text-gray-800">{description}</div>
              <div className="flex flex-col text-gray-800 text-lg">
                {topics.map(function (el, ind) {
                  return <Topic title={el.title} text={el.text} key={ind} />;
                })}
              </div>
              {/* <div className="mt-5 sm:mt-8 mb-8 flex justify-center">
                <div className="rounded-md shadow">
                  <a
                    target="_blank"
                    href={syllabus}
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-800 md:py-4 md:text-lg md:px-10"
                  >
                    Download detailed program
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
