import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";

const description = (
  <div>
    <p class="my-2">
      This is a <b>NO TECHNICAL</b> course
    </p>
    <p class="my-2">
      Looking to get a grip on AI and how to use it to drive your business
      forward?
    </p>
    <p>This course is for designed for CEO and Executives.</p>
  </div>
);

const subtitle = (
  <p>
    Looking to get a grip on AI and how to use it to drive your business
    forward?
    <br />
    This course is for CEO and Executives
  </p>
);

const description_course = (
  <div>
    <p className="text-justify mb-5">
      <span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
        This is a <b>NO TECHNICAL</b> course (no coding)
      </span>
    </p>
    <p>
      Looking to get a grip on AI and how to use it to drive your business
      forward?
    </p>
    <p>
      We'll cover the basics of Machine Learning and Deep Learning, and help you
      understand how to start an AI project from scratch.
    </p>
    <p>
      You'll learn about the minimum professional profiles you need for your
      team, how to manage and store data, and the pros and cons of buying versus
      building or outsourcing your own AI solutions. Plus, we'll delve into
      emerging technologies and how to stay ahead of the curve.
    </p>
    <p>
      By the end of this course, you'll be equipped with the knowledge and
      skills you need to leverage AI for your company's success. The number of
      areas in which AI can be applied has increased in recent years. It is
      expected to grow even faster in the near future. Anyone who thinks of
      neglecting this AI opportunity will be missing a great opportunity for
      growth.
    </p>
  </div>
);

const topics = [
  {
    title:
      "The big picture: classification of AI technologies and which to use",
  },
  {
    title: "What AI can do today: real use business cases and applications",
  },
  {
    title: "How to manage and organise an AI project",
  },
  {
    title: "New team: which professional AI profiles to search?",
  },
  {
    title: "When is it better to outsource an AI solution?",
  },
  {
    title: "Future of AI: trends and directions to be always cutting-edge",
  },
];

export default function AiForExecutives() {
  return (
    <>
      {/* <Banner /> */}
      <HeaderCourse
        title="AI for executives"
        subtitle={subtitle}
        bg_image="bg-aiexec"
      />
      <CallToAction2
        subtitle="CodeNet"
        title="AI for Executives, managers and CEO"
        description={description}
      />
      <TextBlock
        short_uptitle={"Unleash and control AI power"}
        title={"AI for executives, managers and CEO"}
        description={description_course}
        topics={topics}
      />
      <Mentor mentor_names={["Carlo Ceriotti", "Cristiano De Nobili"]} />
      {/* <Venue /> */}
      {/* <ImageGallery /> */}
      {/* <Prices /> */}
      <Footer />
    </>
  );
}
