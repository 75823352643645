import header_img1 from "../images/header1.jpg";
import header_img2 from "../images/header2.jpg";
import header_img4 from "../images/header4.jpg";
import ML_img from "../images/ML_img5.jpg";
import ai_executive_img from "../images/ai_executive_img.jpg";
import NLP_img from "../images/NLP_img.jpg";
import deep_learning from "../images/deep_learning_img.jpg";
import scraping_img from "../images/scraping_img.jpg";
import data_exploration from "../images/data_exploration_img.jpg";
import data_design from "../images/data_design_img2.jpg";
import { NumberedMenuWhite } from "../components/NumberedMenu";

const header_images = [header_img1, header_img2, header_img4];

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function choose_random_img() {
  return header_images[randomInteger(0, header_images.length - 1)];
}

const events = [
  // {
  //   name: "Python",
  //   link: "deeplearning",
  //   image: python_img,
  //   description: (
  //     <div>
  //       <p>
  //         We will guide you through the amazing and powerful Python language,
  //         with theory and many hands-on projects.
  //       </p>
  //     </div>
  //   ),
  // },
  {
    name: "AI for executives",
    link: "ai_for_executives",
    image: ai_executive_img,
    type: ["No coding", "For managers"],
    description: (
      <div>
        <p className="mb-2">
          This is a <b>no-coding</b> overview about AI. The winning recipe for
          CEO, managers and no-tech people willing to know how to use AI to
          solve <b>real world business problems</b> and how to face a full
          end-to-end AI project.
        </p>
      </div>
    ),
  },
  {
    name: "Information Design",
    link: "data_viz_information_design",
    image: data_design,
    type: ["No coding", "Design"],
    description: (
      <div>
        <p className="mb-2">
          Have you ever felt the urge to understand <b>insights</b> by
          presenting data in a visual way? This course let you know how by
          leveraging <b>information design</b> principles.
        </p>
      </div>
    ),
  },
  {
    name: "Scraping",
    link: "scraping",
    image: scraping_img,
    type: ["Python Coding", "Tech"],
    description: (
      <div>
        <p>
          Do you need a <b>massive</b> amount of data from a website to train
          your model? With web scraping, you can get data from web in an
          automated, fast and reliable way with a crawler/bot.
        </p>
      </div>
    ),
  },
  {
    name: "Data Exploration",
    link: "data_exploration",
    image: data_exploration,
    type: ["Python Coding", "Tech"],
    description: (
      <div>
        <p>
          Dirty data? Data manipulation and data cleaning with Python Pandas,
          explore and visualise it with Matplotlib and Seaborn.
        </p>
      </div>
    ),
  },
  {
    name: "Machine Learning",
    link: "machinelearning",
    image: ML_img,
    type: ["Python Coding", "Tech"],
    description: (
      <div>
        <p>
          This course will guide you through the amazing Machine Learning world:
          you will build classifiers, regressors and train / evaluate your own
          models with metrics.
        </p>
      </div>
    ),
  },
  {
    name: "Deep Learning",
    link: "deeplearning",
    image: deep_learning,
    type: ["Python Coding", "Tech"],
    description: (
      <div>
        <p>
          Machine Learning on steroids: what is deep learning and how to build
          powerful models for different applications.
        </p>
      </div>
    ),
  },
  // {
  //   name: "Data Viz",
  //   link: "deeplearning",
  //   image: data_viz,
  //   description: (
  //     <div>
  //       <p className="mb-2">
  //         In this <b>no-coding</b> course, the goal is to learn how to present
  //         complex data in an effective way. Which are the most effective colours
  //         to deliver a specific idea with an infographic? What is the story
  //         telling?
  //       </p>
  //       <p>
  //         You will learn how to present your data at their best to deliver solid
  //         and effective ideas based on data. There will be Python hands-on
  //         exercises and projects.
  //       </p>
  //     </div>
  //   ),
  // },
  {
    name: "NLP",
    link: "nlp",
    image: NLP_img,
    type: ["Python Coding", "Tech"],
    description: (
      <div>
        <p>
          What is a language model? How is it possible to train a machine to
          understand the sentiment of a sentence? This and more in this course,
          where we will use Python and its powerful deep learning libraries.
        </p>
      </div>
    ),
  },
];

const Events = ({ handleSelectionCourse }) => {
  return (
    <div id="courses_section">
      <div className="bg-gray-900 px-4 py-[7rem]">
        <div className="md:ml-[8rem] text-left">
          <p className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
            Choose your courses
          </p>
          <h1 className="text-4xl py-2 text-white mb-0 font-extrabold sm:text-4xl">
            Available courses
          </h1>
          <h2 className="text-xl py-2 text-white mb-0">
            Choose one or more courses by flagging "Choose this course." To know
            more, please click on "More Info" button
          </h2>
        </div>
        <div className="flex">
          <NumberedMenuWhite active={["1"]} />
          <div className="flex flex-wrap py-10 md:mx-[5rem]">
            {events.map((event) => (
              <Event
                img={choose_random_img()}
                name={event.name}
                key={event.name}
                days={event.duration}
                when={event.when}
                event_type={event.type}
                level={event.level}
                mentors={event.mentors}
                where={event.where}
                link={event.link}
                description={event.description}
                image={event.image}
                handleSelectionCourse={handleSelectionCourse}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Event = ({
  name,
  description,
  img,
  where,
  when,
  days,
  level,
  link,
  event_type,
  image,
  handleSelectionCourse,
}) => {
  return (
    <div className="py-4 md:w-1/2 lg:w-1/3 w-full md:pr-6 flex">
      <div className="bg-white rounded-lg overflow-hidden flex flex-col justify-between">
        <div>
          <div>
            <img
              src={image}
              className="h-[10rem] w-full object-cover"
              alt=""
            ></img>
          </div>
          <div className="mx-5">
            <h1 className="text-2xl text-left pt-5 mb-0 font-extrabold">
              {name}
            </h1>
            <div className="mb-3">
              {event_type &&
                event_type.map((sp, ind) => (
                  <span
                    key={ind}
                    className="text-base inline-block my-2 bg-blue-200 text-blue-800 font-medium mr-2 px-3 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
                  >
                    {sp}
                  </span>
                ))}
            </div>
            <div className="text-base text-left">{description}</div>
          </div>
        </div>
        <div className="mx-5">
          <div className="mt-5 sm:mt-8 mb-8 flex justify-between">
            <div className="rounded-md shadow">
              <a
                href={link}
                className="w-full px-4 flex items-center justify-center py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
              >
                More info
              </a>
            </div>
            <div className="text-center flex">
              <label htmlFor={name} className="m-2 self-center">
                Choose this course
              </label>
              <input
                type="checkbox"
                id={name}
                onClick={(event) => handleSelectionCourse(name, event)}
                className="self-center bg-white border-green-300 text-green-500 focus:ring-green-200 shadow checked:shadow-xl w-8 h-8 rounded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
