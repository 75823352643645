import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";

const description = (
  <div>
    <p class="my-2">
      This is a <b>TECHNICAL</b> course (Python coding 🐍)
    </p>
    <p class="my-2">
      What's the difference between supervised, unsupervised and reinforcement
      learning?
      <br />
      How to train a classifier or a regressor? What is a loss function? With
      this theorerical and <b>hands-on course</b>, you will learn this how ML
      works and how to use it on your data.
      <br />
      <b>Bring your laptop and join us!</b>
    </p>
  </div>
);

const subtitle = <p>Learn the principles of Machine Learning with CodeNet</p>;

const description_course = (
  <div>
    <p className="text-justify mb-5">
      <span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
        This is a <b>TECHNICAL</b> course (Python coding 🐍)
      </span>
    </p>
    <p>
      Imagine you have data from patients and you would like to build a model
      able to predict if a new patient (never seen before by the model) has a
      particular patology. Magic? No! Machine Learning.
    </p>
    <p>
      With Machine Learning, you can build models able to detect automatically
      "patterns" in your data and making predictions by "generalisation".
    </p>
    <p>
      In this course you will get a solid foundamentals about ML, what is a
      model, how to train it, how to avoid "overfitting" and choose the best
      model with evaluation metrics.
    </p>
    <p>
      The course is <b>hands-on</b>: we will use Python as coding language with{" "}
      <b>Scikit-learn</b> as Machine Learning library.
    </p>
  </div>
);

const topics = [
  {
    title: "What is Machine Learning",
  },
  {
    title: "Supervised, unsupervised and reinforcement learning",
  },
  {
    title: "What is training and a loss function",
  },
  {
    title: "What is overfitting. Train, validation and test set",
  },
  {
    title: "Evaluation metrics",
  },
];

export default function MachineLearning() {
  return (
    <>
      {/* <Banner /> */}
      <HeaderCourse
        title="Machine Learning"
        subtitle={subtitle}
        bg_image="bg-ml"
      />
      <CallToAction2
        subtitle="CodeNet"
        title="Machine Learning"
        description={description}
      />
      <TextBlock
        short_uptitle={"Discover Machine Learning with CodeNet"}
        title={"Machine Learning - theory and practice"}
        description={description_course}
        topics={topics}
      />
      <Mentor mentor_names={["Carlo Ceriotti", "Cristiano De Nobili"]} />
      {/* <Venue /> */}
      {/* <ImageGallery /> */}
      {/* <Prices /> */}
      <Footer />
    </>
  );
}
