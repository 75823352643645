export default function CallToAction({ subtitle, title, description }) {
  if (subtitle === "") {
    subtitle = "What is CodeNet?";
  }
  if (title === "") {
    title = "Deep Learning Experts Training";
  }
  if (description === "") {
    description =
      "CodeNet organises bootcamps about cutting edge topics in Deep Learning. \
      You will be trained by experts with years of experience \
      guiding you through theory and hands-on business exercises.";
  }

  return (
    <div className="py-12 md:bg-hero-pattern4 md:bg-cover md:text-white bg-fixed">
      <div
        className="max-w-4xl rounded-3xl overflow-hidden mx-auto 
      h-full md:p-12 sm:pb-10 xs:p-3 md:bg-black text-center
      md:bg-opacity-80"
      >
        <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
          {subtitle}
        </h2>
        <h2 className="text-3xl font-extrabold sm:text-4xl">{title}</h2>
        <div className="text-xl text-justify">
          <div className="px-5 py-5">
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
