export const NumberedMenuWhite = ({ active }) => {
  const style_to_assign = `text-xl text-white text-center 
    border-solid border-2 border-white p-[0.5rem] rounded-[4rem] my-3 w-[3rem]`;

  return (
    <div className="hidden lg:block">
      <a href="#courses_section" id="1">
        <p
          className={`${style_to_assign} 
          ${active.includes("1") ? "bg-white text-black" : ""}`}
        >
          1
        </p>
      </a>
      <a href="#locations_section" id="2">
        <p
          className={`${style_to_assign} 
                    ${active.includes("2") ? "bg-white text-black" : ""}`}
        >
          2
        </p>
      </a>
      <a href="#request_section" id="3">
        <p
          className={`${style_to_assign} 
          ${active.includes("3") ? "bg-white text-black" : ""}`}
        >
          3
        </p>
      </a>
    </div>
  );
};

export const NumberedMenuBlack = ({ active }) => {
  const style_to_assign = `
  text-xl 
  text-center 
  border-solid border-2 
  border-black p-[0.5rem] 
  rounded-[4rem] my-3 w-[3rem]`;

  return (
    <div className="hidden lg:block">
      <a href="#courses_section" id="1">
        <p
          className={`${style_to_assign} ${
            active.includes("1") ? "bg-black text-white" : ""
          }`}
        >
          1
        </p>
      </a>
      <a href="#locations_section" id="2">
        <p
          className={`${style_to_assign} ${
            active.includes("2") ? "bg-black text-white" : ""
          }`}
        >
          2
        </p>
      </a>
      <a href="#request_section" id="3">
        <p
          className={`${style_to_assign}  ${
            active.includes("3") ? "bg-black text-white" : ""
          }`}
        >
          3
        </p>
      </a>
    </div>
  );
};
