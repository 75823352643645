import Header from "../components/Header";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import Events from "../components/Events";
import Locations from "../components/Locations";
import BasicSection from "../components/BasicSection";
import ComposeExperience from "../components/ComposeExperience";
import { useState } from "react";
import { months } from "../components/ComposeExperience";
import sendEmail from "../utils/sendEmail";
// import NumberNavigation from "../components/NumberedMenu";
import Team from "../components/Team";

export default function Homepage() {
  const [selection, setSelection] = useState({ locations: [], courses: [] });
  const [email, setEmail] = useState("");
  const [chosenPeriod, setChosenPeriod] = useState(months[0]);
  const [statusCode, setStatusCode] = useState({ status: "" });

  const handleSelectionLocation = (location, event) => {
    const status = event.target.checked;
    if (status) {
      setSelection({
        ...selection,
        locations: [...selection.locations, location],
      });
    } else {
      if (selection.locations.indexOf(location) !== -1) {
        setSelection({
          ...selection,
          locations: selection.locations.filter((loc) => loc !== location),
        });
      }
    }
  };

  const handleSelectionCourse = (course, event) => {
    const status = event.target.checked;
    if (status) {
      setSelection({
        ...selection,
        courses: [...selection.courses, course],
      });
    } else {
      if (selection.courses.indexOf(course) !== -1) {
        setSelection({
          ...selection,
          courses: selection.courses.filter((loc) => loc !== course),
        });
      }
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    console.log({ ...selection, email, chosenPeriod });
    if (
      email.includes("@") &&
      selection.courses.length !== 0 &&
      selection.locations.length !== 0
    ) {
      sendEmail({
        email,
        selection,
        chosenPeriod,
        setStatusCode,
      });
    } else {
      setStatusCode({ status: "Wrong" });
    }

    event.preventDefault();
  };

  const handleChosenPeriod = (event) => {
    setChosenPeriod(event.target.value);
  };

  return (
    <>
      {/* <Banner /> */}
      <Header />
      <CallToAction subtitle="" description="" title="" />
      <hr />
      <Team />
      <BasicSection subtitle="" description="" title="" />
      <Events handleSelectionCourse={handleSelectionCourse} />
      <Locations handleSelectionLocation={handleSelectionLocation} />
      <ComposeExperience
        subtitle=""
        title=""
        chosen_locations={selection.locations}
        chosen_courses={selection.courses}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        email={email}
        handleChosenPeriod={handleChosenPeriod}
        statusCode={statusCode}
      />
      {/* <ComposeExperienceBottom
        subtitle="Choose your experience"
        title="You are interested in..."
        chosen_locations={selection.locations}
        chosen_courses={selection.courses}
      /> */}
      <Footer />
    </>
  );
}
