export default function Topic({ title, text }) {
  return (
    <div className="text-center rounded-lg mt-4 overflow-hidden text-gray-900 border-black border-solid border">
      <p className="text-2xl leading-8 py-2 px-3 font-extrabold tracking-tight bg-slate-100">
        {title}
      </p>
      {text && <p className="mt-3 p-2">{text}</p>}
    </div>
  );
}
