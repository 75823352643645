import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Homepage from "./Pages/Homepage";
import DeepLearningPage from "./Pages/DeepLearningPage";
import AiForExecutives from "./Pages/AiForExecutives";
import MachineLearning from "./Pages/MachineLearning";
import DataExploration from "./Pages/DataExploration";
import DataVizInformationDesign from "./Pages/DataVizInformationDesign";
import Scraping from "./Pages/Scraping";
import NLP from "./Pages/NLP";
// import PythonPage from "./Pages/PythonPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/deeplearning" element={<DeepLearningPage />} />
      <Route path="/ai_for_executives" element={<AiForExecutives />} />
      <Route
        path="/data_viz_information_design"
        element={<DataVizInformationDesign />}
      />
      <Route path="/data_exploration" element={<DataExploration />} />
      <Route path="/scraping" element={<Scraping />} />
      <Route path="/machinelearning" element={<MachineLearning />} />
      <Route path="/nlp" element={<NLP />} />
    </Routes>
  </BrowserRouter>
);

// ReactDOM.createRoot(<Router />, document.getElementById("root"));
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
