import CallToAction2 from "../components/CallToAction2";
import TextBlock from "../components/TextBlock";
import Prices from "../components/Prices";
import Mentor from "../components/Mentor";
import Venue from "../components/Venue";
import Footer from "../components/Footer";
import HeaderCourse from "../components/HeaderCourse";
import ImageGallery from "../components/ImageGallery";

const description = (
  <div>
    <p class="my-2">
      This is a <b>TECHNICAL</b> course (Python coding 🐍)
    </p>
    <p class="my-2">
      Do machines understand human language? With this course, you will discover
      it!
      <br />
      <b>Bring your laptop and join us!</b>
    </p>
  </div>
);

const subtitle = <p>Learn what is a language model and how to build it</p>;

const description_course = (
  <div>
    <p className="text-justify mb-5">
      <span className="text-xl m-2 bg-blue-100 text-blue-800 font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
        This is a <b>TECHNICAL</b> course (Python coding 🐍)
      </span>
    </p>
    <p>
      We will deep dive into NLP world by understanding how a RRN (Recurrent
      Neural Network) works and how it is structured.
    </p>
    <p>
      You will understand what is a language model and how it is build in
      practice.
    </p>
    <p>
      We will talk about BERT and Transformers, the most cutting-edge techniques
      for NLP nowadays.
    </p>
    <p>
      The course is <b>hands-on</b>: we will use Python as coding language with
      package <b>PyTorch</b> as Deep Learning framework.
    </p>
  </div>
);

const topics = [
  {
    title: "Do machines understand human language?",
  },
  {
    title: "What is a language model?",
  },
  {
    title: "What is a Recurrent Neural Network and how does it work?",
  },
  {
    title: "What is a Transformer?",
  },
  {
    title:
      "How to build a model able to classify intentions, sentiment and features from text?",
  },
];

export default function MachineLearning() {
  return (
    <>
      {/* <Banner /> */}
      <HeaderCourse
        title="Natural Language Processing"
        subtitle={subtitle}
        bg_image="bg-nlp"
      />
      <CallToAction2
        subtitle="CodeNet"
        title="Natural Language Processing"
        description={description}
      />
      <TextBlock
        short_uptitle="Natural Language Processing"
        title="Natural Language Processing - theory and practice"
        description={description_course}
        topics={topics}
      />
      <Mentor mentor_names={["Carlo Ceriotti", "Cristiano De Nobili"]} />
      {/* <Venue /> */}
      {/* <ImageGallery /> */}
      {/* <Prices /> */}
      <Footer />
    </>
  );
}
