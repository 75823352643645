import fuerteventura_img from "../images/fuerteventura_img.jpg";
import lisbon from "../images/lisbon.jpg";
import verona from "../images/verona.jpg";
import your_place_img from "../images/your_place_img.jpg";
import mountain1 from "../images/mountain1.jpg";
import alba from "../images/alba.jpg";
import varese from "../images/varese.jpg";
import firenze from "../images/firenze.jpg";
import ischia from "../images/ischia.jpg";
import isole_pontine from "../images/isole_pontine.jpg";
import colli_piacentini from "../images/colli_piacentini.jpg";
import rodi_garganico from "../images/rodi_garganico.jpg";
import tenerife from "../images/tenerife2.jpg";
import { NumberedMenuBlack } from "../components/NumberedMenu";

const locations = [
  {
    location: "In your company",
    key: "In your location",
    country: "",
    subtitle: "",
    link: "deeplearning",
    image: your_place_img,
    sport: null,
    description: (
      <div>
        <p>
          We can organise the lessons directly in your company or in a city you
          prefer.
        </p>
      </div>
    ),
  },
  {
    location: "Alba",
    key: "albe",
    country: "Italy",
    subtitle: "Countryside",
    link: "deeplearning",
    image: alba,
    sport: ["Trekking", "Wine tasting", "Yoga"],
    description: (
      <div>
        <p>
          Alba (Piemonte) is known for its wine production, particularly the
          Barolo and Barbaresco wines. Alba is also famous for its White Truffle
          Festival, which takes place every October. The city is surrounded by
          rolling hills, vineyards, and ancient castles, making it a popular
          tourist destination.
        </p>
      </div>
    ),
  },
  {
    location: "Valle d'Aosta",
    key: "valle_aosta",
    country: "Italy 🇮🇹",
    subtitle: "Mountain",
    link: "",
    image: mountain1,
    sport: [
      "Trekking",
      "Mountain Bike/eBike",
      "Skiing",
      "Climbing",
      "SPA",
      "Yoga",
    ],
    description: (
      <div>
        <p>
          Valle d'Aosta is known for its stunning Alpine scenery and rich
          history, with castles and fortresses dating back to the Roman Empire.
          The city is also famous for its local cuisine, wine, and skiing
          destinations. Visitors can enjoy the stunning views, adventure sports,
          and cultural attractions in Valle d'Aosta.
        </p>
      </div>
    ),
  },
  {
    location: "Verona",
    key: "verona",
    country: "Italy 🇮🇹",
    subtitle: "Countryside",
    link: "",
    image: verona,
    sport: ["Trekking", "Mountain Bike/eBike", "Wine Tasting", "Yoga"],
    description: (
      <div>
        <p>
          Verona is a beautiful and historic city in northern Italy. It is best
          known for its stunning architecture, rich cultural heritage, and its
          association with Shakespeare's Romeo and Juliet. With a charming
          medieval center, breathtaking views, and world-class dining options,
          Verona is a must-visit destination for travelers.
        </p>
      </div>
    ),
  },
  {
    location: "Varese",
    key: "varese",
    country: "Italy 🇮🇹",
    subtitle: "Countryside",
    link: "deeplearning",
    image: varese,
    sport: ["Trekking", "Mountain Bike/eBike", "Yoga"],
    description: (
      <div>
        <p>
          Varese is a city located in the Lombardy region of northern Italy.
          Known for its scenic beauty and surrounded by rolling hills and
          forests, it is home to stunning architectural masterpieces, historical
          landmarks, and lush parks. A bustling city with a rich cultural
          heritage, it is a popular tourist destination, attracting visitors
          from all over the world.
        </p>
      </div>
    ),
  },
  {
    location: "Tenerife",
    key: "tenerife",
    country: "Spain 🇪🇸",
    subtitle: "Countryside",
    link: "",
    image: tenerife,
    sport: [
      "Surfing",
      "Sailing",
      "Trekking",
      "Mountain Bike/eBike",
      "Aloe Farms",
      "Yoga",
    ],
    description: (
      <div>
        <p>
          Tenerife is a city located in the Canary Islands, Spain. It is the
          largest of the seven islands and is known for its beautiful beaches,
          lush vegetation, and vibrant nightlife. It attracts millions of
          tourists every year with its rich history, cultural events, and
          stunning scenery. Whether you're looking for adventure or relaxation,
          Tenerife is the perfect destination for you.
        </p>
      </div>
    ),
  },
  {
    location: "Fuerteventura",
    key: "Fuerteventura",
    country: "Spain 🇪🇸",
    subtitle: "Seaside",
    link: "deeplearning",
    image: fuerteventura_img,
    sport: ["Surfing", "Trekking", "Mountain Bike/eBike"],
    description: (
      <div>
        <p>
          Known for its pristine beaches, turquoise waters and year-round
          sunshine. A paradise for water sports enthusiasts and beach lovers,
          also offers desert landscapes, traditional villages and protected
          natural parks.
        </p>
      </div>
    ),
  },
  {
    location: "Firenze",
    key: "Italy",
    country: "Italy 🇮🇹",
    subtitle: "Seaside",
    link: "deeplearning",
    image: firenze,
    sport: ["Cultural Visits", "Wine Tasting", "Yoga", "Mountain Bike/eBike"],
    description: (
      <div>
        <p>
          Florence is a historical city in Italy famous for its art,
          architecture, and cultural heritage. It is considered the birthplace
          of the Renaissance and is home to many famous masterpieces such as the
          David statue and the Uffizi Gallery. The city also boasts of its
          charming streets, churches, and palaces. A must-visit destination for
          any art lover and cultural aficionado.
        </p>
      </div>
    ),
  },
  {
    location: "Ischia",
    key: "Italy",
    country: "Italy 🇮🇹",
    subtitle: "Seaside",
    link: "deeplearning",
    image: ischia,
    sport: [
      "Trekking",
      "Cultural Visits",
      "Mountain Bike/eBike",
      "SPA",
      "Yoga",
    ],
    description: (
      <div>
        <p>
          Ischia is an Italian island located in the Bay of Naples. Known for
          its thermal springs, stunning beaches, and natural beauty, Ischia is a
          popular tourist destination and is famous for its vibrant nightlife,
          unique culinary scene, and rich history.
        </p>
      </div>
    ),
  },
  {
    location: "Isole Pontine",
    key: "isole_pontine",
    country: "Italy 🇮🇹",
    subtitle: "Seaside",
    link: "deeplearning",
    image: isole_pontine,
    sport: ["Trekking", "Yoga", "Sailing", "Wine Tasting"],
    description: (
      <div>
        <p>
          Isole Pontine are a group of Italian islands located in the Tyrrhenian
          Sea. They include Ponza, Ventotene, Palmarola, Zannone, and others.
          These islands are known for their picturesque beauty, crystal-clear
          waters, and rich history. A popular summer destination for Italians
          and tourists, Isole Pontine offer a unique blend of natural beauty,
          cultural heritage, and modern amenities.
        </p>
      </div>
    ),
  },
  {
    location: "Colli Piacentini",
    key: "colli_piacentini",
    country: "Italy 🇮🇹",
    subtitle: "Countryside",
    link: "deeplearning",
    image: colli_piacentini,
    sport: [
      "Trekking",
      "Cultural Visits",
      "Mountain Bike/eBike",
      "SPA",
      "Yoga",
    ],
    description: (
      <div>
        Colli Piacentini is a hilly region in northern Italy known for its
        rolling countryside, scenic views, and rich wine-making tradition. The
        area is famous for its wines, food, and beautiful landscapes. It is a
        popular tourist destination for those seeking a peaceful escape from the
        city.
      </div>
    ),
  },
  {
    location: "Lisbon",
    key: "Lisbon",
    country: "Portugal 🇵🇹",
    subtitle: "Seaside",
    link: "deeplearning",
    image: lisbon,
    sport: [
      "Surfing",
      "Wine tasting",
      "Trekking",
      "Mountain Bike/eBike",
      "Yoga",
    ],
    description: (
      <div>
        <p>
          Portugal's <b>charming capital</b>, boasts historic neighborhoods,
          delicious seafood, vibrant nightlife, scenic views from its hills, and
          cultural landmarks. A city with a unique blend of history and
          modernity, it's a must-visit destination in Europe.
        </p>
      </div>
    ),
  },
  {
    location: "Rodi Garganico",
    key: "rodi_garganico",
    country: "Italy 🇮🇹",
    subtitle: "Seaside",
    link: "",
    image: rodi_garganico,
    sport: ["Diving", "Trekking", "Mountain Bike/eBike", "Sailing"],
    description: (
      <div>
        <p>
          Rodi Garganico is a coastal town located in the Apulia region of
          Italy. It offers stunning beaches, crystal-clear waters, and a
          laid-back atmosphere. This charming town is surrounded by hills, olive
          groves, and vineyards. Visitors can explore the historic center and
          enjoy local cuisine, such as seafood and traditional dishes. Ideal for
          a peaceful holiday, Rodi Garganico is a hidden gem in Italy's southern
          region.
        </p>
      </div>
    ),
  },
  // {
  //   location: "Viterbo",
  //   key: "viterbo",
  //   country: "Italy 🇮🇹",
  //   subtitle: "countryside",
  //   link: "",
  //   image: lisbon,
  //   sport: ["Hiking", "Mountain Bike", "Skiing", "Climbing"],
  //   description: (
  //     <div>
  //       <p>
  //         Portugal's <b>charming capital</b>, boasts historic neighborhoods,
  //         delicious seafood, vibrant nightlife, scenic views from its hills, and
  //         cultural landmarks. A city with a unique blend of history and
  //         modernity, it's a must-visit destination in Europe.
  //       </p>
  //     </div>
  //   ),
  // },
];

const Locations = ({ handleSelectionLocation }) => {
  return (
    <div className="px-4 py-[7rem]" id="locations_section">
      <div className="md:ml-[8rem] text-left">
        <p className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
          Choose your locations
        </p>
        <h1 className="text-4xl py-2 text-black mb-0 font-extrabold sm:text-4xl">
          Available locations
        </h1>
        <h2 className="text-xl py-2 text-black mb-0">
          Choose one or more locations by flagging "Choose this location".
        </h2>
      </div>
      <div className="px-4">
        <div className="flex">
          <NumberedMenuBlack active={["1", "2"]} />
          <div className="flex flex-wrap py-10 md:mx-[4rem]">
            {locations.map(
              ({
                location,
                subtitle,
                country,
                sport,
                name,
                key,
                link,
                description,
                image,
              }) => (
                <Location
                  location={location}
                  subtitle={subtitle}
                  country={country}
                  sport={sport}
                  link={link}
                  key={key}
                  description={description}
                  image={image}
                  handleSelectionLocation={handleSelectionLocation}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Location = ({
  location,
  country,
  sport,
  link,
  description,
  image,
  subtitle,
  handleSelectionLocation,
}) => {
  return (
    <div
      key={location}
      className="py-4 md:w-1/2 md:gap-3 lg:w-1/3 w-full md:pr-6 flex grow-1/3"
    >
      <div className="bg-white shadow-lg shadow-black-100 rounded-lg overflow-hidden flex flex-col justify-between">
        <div>
          <div>
            <img
              src={image}
              className="h-[12rem] w-full object-cover"
              alt=""
            ></img>
          </div>
          <div className="mx-5">
            <h1 className="text-2xl text-left py-5 mb-0 font-extrabold">
              {location}
            </h1>
            <div className="text-left">{description}</div>
            <div className="text-left">
              {sport && (
                <p className="mt-5 text-left leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
                  Available activities
                </p>
              )}
              <div className="mt-4 flex flex-wrap">
                <div>
                  {sport &&
                    sport.map((sp, ind) => (
                      <span
                        key={ind}
                        className="text-base inline-block my-2 bg-blue-200 text-blue-800 font-medium mr-2 px-3 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800"
                      >
                        {sp}
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-8 mb-8 flex justify-around">
          {/* <div className="rounded-md shadow">
            <a
              href={link}
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
            >
              More info
            </a>
          </div> */}
          <div className="text-center flex">
            <label htmlFor={location} className="m-2 self-center">
              Choose this location
            </label>
            <input
              type="checkbox"
              id={location}
              onClick={(event) => handleSelectionLocation(location, event)}
              className="self-center bg-white border-green-300 text-green-500 focus:ring-green-200 shadow checked:shadow-xl w-8 h-8 rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
